body {
  // display: flex
  // justify-content: center
  background: #EFEAE7;
  position: relative; }

a {
  text-decoration: none; }

#CybotCookiebotDialogBody {
  max-width: 70%!important; }

.menu-width-transition {
  @include md {
    margin-top:4rem!important {} } }

.font-icon {
  font-family: rdl-icons; }
.font-icon1 {
  font-family: rdl-icons; }



.icon-arrow-down:before {
  content: "\0041"; }

.icon-arrow:before {
  content: "\0042"; }

.icon-caution-circle:before {
  content: "\0043"; }

.icon-check-circle:before {
  content: "\0044"; }

.icon-check:before {
  content: "\0045"; }

.icon-close:before {
  content: "\0046"; }

.icon-facebook:before {
  content: "\0047"; }

.icon-gear:before {
  content: "\0048"; }

.icon-google-plus:before {
  content: "\0049"; }

.icon-instagram:before {
  content: "\004a"; }

.icon-hamburguer:before {
  content: "\004b"; }

.icon-pause:before {
  content: "\004c"; }

.icon-power:before {
  content: "\004d"; }

.icon-user:before {
  content: "\004e"; }

.icon-search:before {
  content: "\0050"; }

.icon-spotify-alt:before {
  content: "\0051"; }

.icon-spotify:before {
  content: "\0052"; }

.icon-triangle:before {
  content: "\0053"; }

.icon-twitter:before {
  content: "\0054"; }

.icon-vimeo:before {
  content: "\0055"; }

.icon-youtube:before {
  content: "\0056"; }

.icon-notification:before {
  content: "\e901"; }

.icon-RDL-notification:before {
  content: "\e904"; }

.icon-new-notification:before {
  content: "\e905"; }

.icon-logo:before {
  content: "\004f"; }
.icon-rockdelux:before {
  content: "\004f"; }
.icon-rdl:before {
  content: "\004f"; }


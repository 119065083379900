@import "mixins";
@import "variables";
@import "fonts";
@import "buttons";
@import "base";
@import "TopHeader";
@import "Launcher";
@import "artist";
@import "scroll";
@import "material";
@import "~slick-carousel/slick/slick.css";
@import "slick.scss";
@import "./react-datepicker";
@import "react-player";
@import "emojimart";
@import "chat";
@import "icons";
@import "~react-id-swiper/lib/styles/scss/swiper.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css");
@import url("https://use.typekit.net/yhb8jrk.css");
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');

.pin-b-95 {
  bottom: 95px;
}

input.caretRed {
  caret-color: #ff4646;
}

input[type=date]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type=time]::-webkit-calendar-picker-indicator {
  display: none;
}

.full-width {
  width: 100%;
}

.ab-feed .ab-card .ab-close-button {
  opacity: 1 !important;
}

.appearance-none {
     -moz-appearance: none;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 0;
}

.select-appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
}

.innerTextPadding {
  p {
    padding-bottom: 1.5rem;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

.hasLinksAndBolds {
  strong {
    font-weight: bold;
  }

  a {
    font-style: normal;
    color: #ff4646;
  }

  em {
    font-style: italic;
  }
}

.styles_closeButton__20ID4 {
  display: none !important;
}

.placeholder-gray::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #939399;
  opacity: 1; /* Firefox */
}

.placeholder-gray:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #939399;
  opacity: 1; /* Firefox */
}

.placeholder-gray::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #939399;
  opacity: 1; /* Firefox */
}

.placeholder-gray:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #939399;
}

.placeholder-red::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ff4646;
  opacity: 1; /* Firefox */
}

.placeholder-red:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ff4646;
  opacity: 1; /* Firefox */
}

.placeholder-red::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ff4646;
  opacity: 1; /* Firefox */
}

.placeholder-red:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ff4646;
}

.placeholder-red::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ff4646;
}

.transition-opacity {
  transition: opacity .25s linear;
}

// the cloud 

.simple-cloud .tag-cloud-tag {
  cursor: pointer;
}

.simple-cloud .tag-cloud-tag:hover {
  text-decoration: underline;
}

//swiper carousel changes

.swiper-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {
  left: auto;
  right: 38px;
  bottom: 42px;
  width: auto;
  position: absolute;
  transition: 300ms background-color;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination-mobile {
  right: 18px !important;
  bottom: 30px !important;
}

.swiper-container-horizontal>.swiper-pagination-tickets {
  text-align: center;
  bottom: 0;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  left: 19px;
  right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  right: 19px;
  left: auto;
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, .25);
  position: absolute;

  .swiper-pagination-progressbar-fill {
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
  }
}

.swiper-pagination-bullet {
  margin: 0 2.7px !important;
  width: 17px;
  height: 4px;
  display: inline-block;
  border-radius: 0%;
  background: #fff;
  opacity: 1;
  outline: none;
}

// Tablet
@media screen and (min-width: 714px) {
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal>.swiper-pagination-bullets {
    right: 25px;
    bottom: 24px;
  }

  .swiper-pagination-bullet {
    margin: 0 4px !important;
    width: 25px;
    height: 5px;
  }
}

// Laptop
@media screen and (min-width: 1280px) {
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal>.swiper-pagination-bullets {
    right: 35px;
    bottom: 25px;
  }

  .swiper-pagination-bullet {
    width: 33px;
    height: 7px;
  }
}

// Desktop
@media screen and (min-width: 1440px) {
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal>.swiper-pagination-bullets {
    right: 35px;
    bottom: 29px;
  }

  .swiper-pagination-bullet {
    width: 33px;
    height: 7px;
  }
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #ff4646;
}

.swiper-pagination-tickets .swiper-pagination-bullet {
  background: transparent;
  border: 1px black solid;
}

.swiper-pagination-tickets .swiper-pagination-bullet-active {
  opacity: 1;
  background: #000;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, .25);

  .swiper-pagination-progressbar-fill {
    background: #fff;
  }
}

.swiper-slide.w-auto {
  padding-bottom: 20px;
  margin-right: 1rem;
}

.swiper-slide.w-auto:last-child {
  margin-right: 0;
}

.collapsible-page .swiper-slide.w-auto {
  margin-right: 1rem;
  margin-left: 0;
  padding-bottom: 20px;
}

.c-tickets {
  margin-left: auto;
  margin-right: auto;
}

.collapsible-page .c-tickets {
  margin-left: 0;
}

.modal-gallery-slider .swiper-container {
  width: 100%;
}

.thumbnail-slider .swiper-slide {
  border: 2px solid transparent;
}

.thumbnail-slider .swiper-slide-active {
  border: 2px solid white;
}

//pin halftop and halfbottom
.pin-ht {
  top: 50%;
}

.pin-hb {
  bottom: 50%;
}

.rotate-180 {
  transform: rotate(180deg);
}

// ------------------------------------------------------------------------------------------------------------------------------

[class^="MuiFormControl-root"] {
  max-width: 100%;
  width: 100%;

  [class^="MuiSelect-root"] {
    display: flex;
    border: 1px solid #fff;
    border-radius: 999px;
    font-family: GT-America-Mono-Regular;
    color: #fff;

    &.active {
      color: #000;
      background: #fff;
    }

    [class^="MuiSelect-select"] {
      flex: 1;
      padding: 6px 0px 6px 16px;
      overflow: hidden;
      width: 50px;
      z-index: 50;

      >div {
        display: flex;
        align-items: center;
      }
    }
  }

  [class^="MuiInputBase-root"] {
    &:before,
    &:after {
      display: none !important;
    }
  }

  [class^="MuiSelect-select"] {
    padding-left: 8px;

    &:focus {
      background: none !important;
    }
  }

  img {
    -webkit-animation: none;
            animation: none;
    filter: none;
  }
}

.country-list {
  padding: 0;
  padding-top: 7px;
  height: 20vh !important;
  background: #000;
  border-radius: 8px;
  overflow-y: scroll;
  margin-top: 6rem;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 13px solid #000;
    top: 0;
    left: 16px;
  }
}

[class^="MuiPaper-root"] {
  box-shadow: none !important;
  background: none !important;
  margin-top: 5px;
  border-radius: 0 !important;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    //: 13px solid #fff;
    top: 0;
    left: 16px;
  }

  [class^="MuiList-root"] {
    padding: 0;
    padding-top: 7px;
    height: 20vh !important;
    margin-top: 13px;
    background: #fff;
    border-radius: 8px;
    overflow-y: auto;
    position: relative;
  }

  [class^="MuiButtonBase-root"] {
    color: #000 !important;
    font-family: GT-America-Mono-Regular !important;
    font-size: 12px;
    border-bottom: 0;
    padding: 8px 30px 8px 15px;
    background: none !important;

    >div {
      display: flex;
      align-items: center;
    }

    img {
      -webkit-animation: none;
              animation: none;
      filter: none;
    }
  }
}

// ------------------------------------------------------------------------------------------------------------------------------

.carousel75-is-animating {
  transition: transform 400ms cubic-bezier(.5, 0, .5, 1);

  [class^="MuiInputBase-root"] {
    &:before,
    &:after {
      display: none !important;
    }
  }
}

// ------------------------------------------------------------------------------------------------------------------------------

.scrollbar__black::-webkit-scrollbar-track {
  border: 1px solid black;
  background-color: #f5f5f5;
}

.scrollbar__black::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.scrollbar__black::-webkit-scrollbar-thumb {
  background-color: #000;
}

.collapsible-sidebar {
  top: auto;
  bottom: auto;
}

.sticky-sidebar {
  position: fixed !important;
  padding-top: 7.5rem !important;
  padding-right: 2rem !important;
  margin-right: 16px !important;
}

.sticky-sidebar.sticky-top {
  top: 0 !important;
  bottom: auto !important;
}

.sticky-sidebar.sticky-bottom {
  bottom: 352px !important;
  top: auto !important;
}

.MuiCollapse-wrapperInner .c-tickets {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tag-16 {
  font-size: 16px;
  font-family: 'GT-America-Mono-Regular';
}

.tag-22 {
  font-size: 22px;
  font-family: 'GT-America-Mono-Medium';
}

.tag-28 {
  font-size: 28px;
  font-family: 'GT-America-Mono-Medium';
}

.tag-34 {
  font-size: 34px;
  font-family: 'GT-America-Mono-Bold';
}

.tag-40 {
  font-size: 40px;
  font-family: 'GT-America-Standard-Black';
}

.artistcloud-tag:last-child span:last-child {
  display: none;
}

.ab-feed .ab-card .ab-title {
  color: #000;
  font-family: 'GT-America-Standard-Black';
}

.ab-feed .ab-card .ab-description {
  font-family: 'GT-America-Standard-Regular';
  display: flex;
  flex-direction: column;
}

.ab-feed .ab-card .ab-card-body .ab-url-area {
  text-transform: uppercase;
  text-align: left;
  cursor: pointer;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  width: 165px;
  margin-top: 18px;
  color: #ff4646;
}

.ab-feed .ab-card a {
  font-family: 'GT-America-Standard-Bold';
}

body .ab-feed .ab-feed-buttons-wrapper {
  background-color: #ff4646;
}
